declare const require: any;
declare const google: any;
const vtbDataTransformer = require('@sitespirit/vtb-transformer');
import { AgmMap } from '@agm/core';
import { Component, OnInit, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventService } from '../services/event.service';
import { PricesService } from '../services/prices.service';

@Component({
  selector: 'app-template-container',
  templateUrl: './template-container.component.html',
  styleUrls: ['./template-container.component.css']
})
export class TemplateContainerComponent {
  @ViewChildren(AgmMap) public maps;
  
  itinerary: any;
  changedZoom = false;

  constructor(private _activatedRoute: ActivatedRoute, private _eventService: EventService, private pricesService:PricesService) {
    const data = this._activatedRoute.snapshot.data;
    this.itinerary = this.pricesService.init(data.itinerary);
  }

  ngAfterViewInit()
  {
    var event = new CustomEvent('vtbInited');
    window.dispatchEvent(event);
    console.log('inited');
  }

  googleMapsReady($event) {
    // Quickfix, needs to be rewritten...
    if (typeof google !== undefined) {
      google.maps.event.addListenerOnce($event, 'tilesloaded', () => {
        setTimeout(() => {
          this._eventService.setMapsReady();
        }, 500); //little break to draw everything on map
      });
    }
  }

  zoomTo($event, zoomTo)
  {
    if(!zoomTo || isNaN(parseFloat(zoomTo))) return;
    
    if(this.changedZoom) return;
    this.changedZoom = true;

    this.maps.forEach(map => {
      if(map.zoom) {
        map._mapsWrapper._map.then(m => {
          m.setZoom(parseFloat(zoomTo));
        });
      }
    });
  }

}
